:root {
    --height: 20px;
    --maxheight: 30px;
    --minheight: 4px;
    --color: #ffffff;
}

.black {
    --color: #000000;
}

.loader {
    width: 8px;
    height: var(--height);
    border-radius: 4px;
    display: block;
    margin: 20px auto;
    position: relative;
    background: var(--color);
    color: #FFFFFF;
    box-sizing: border-box;
    animation: animloader 0.3s -0.3s linear infinite alternate;
}

.loader::after, .loader::before {
    content: '';
    width: 8px;
    height: var(--height);;
    border-radius: 4px;
    background: var(--color);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    box-sizing: border-box;
    animation: animloader 0.3s linear infinite alternate;
}

.loader::before {
    left: -20px;
    animation-delay: 0s;
}

@keyframes animloader {
    0% {
        height: var(--maxheight)
    }
    100% {
        height: var(--minheight)
    }
}

.loader__sm,
.loader__sm::after, .loader__sm::before {
    width: 4px;
    --height: 5px;
    --maxheight: 10px
}

.loader__medium {
    --height: 7px;
    --maxheight: 15px;
}

.loader__large {
    --height: 10px;
    --maxheight: 20px;
}

.loader__xl {
    --height: 16px;
    --maxheight: 35px;
}