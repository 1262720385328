@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    font-family: 'Exo', sans-serif;
}

.bgimg {
     background-image: url("./assets/images/2023-02-24_00.21.11.png");
    background-repeat: no-repeat;
}
.leaflet-control-attribution {
    display: none !important;
}
/* :root { */
/*     --background-color: #f8f8f8; */
/*     --gray-50: #ffffff; */
/*     --gray-100: #d0d0d0; */
/*     --gray-200: #afafaf; */
/*     --gray-300: #8f8f8f; */
/*     --gray-400: #717171; */
/*     --gray-500: #555555; */
/*     --gray-600: #393939; */
/*     --purple-100: #d5c9fa; */
/*     --purple-200: #b8a3f6; */
/*     --purple-300: #997cf2; */
/*     --purple-400: #7a54ef; */
/*     --purple-500: #582ddc; */
/*     --purple-600: #3c1e95; */
/*     --red-100: #f7c4ba; */
/*     --red-200: #f29887; */
/*     --red-300: #eb664d; */
/*     --red-400: #de2300; */
/*     --red-500: #a81b00; */
/*     --red-600: #731200; */
/*     --highlight-hover: rgb(0 0 0 / 0.07); */
/*     --highlight-pressed: rgb(0 0 0 / 0.15); */
/* } */

/* !* Dark: https://leonardocolor.io/theme.html?name=Dark&config=%7B%22baseScale%22%3A%22Gray%22%2C%22colorScales%22%3A%5B%7B%22name%22%3A%22Gray%22%2C%22colorKeys%22%3A%5B%22%23000000%22%5D%2C%22colorspace%22%3A%22RGB%22%2C%22ratios%22%3A%5B%22-1.12%22%2C%221.45%22%2C%222.05%22%2C%223.02%22%2C%224.54%22%2C%227%22%2C%2210.86%22%5D%2C%22smooth%22%3Afalse%7D%2C%7B%22name%22%3A%22Purple%22%2C%22colorKeys%22%3A%5B%22%235e30eb%22%5D%2C%22colorspace%22%3A%22RGB%22%2C%22ratios%22%3A%5B%22-1.12%22%2C%221.45%22%2C%222.05%22%2C%223.02%22%2C%224.54%22%2C%227%22%2C%2210.86%22%5D%2C%22smooth%22%3Afalse%7D%2C%7B%22name%22%3A%22Red%22%2C%22colorKeys%22%3A%5B%22%23e32400%22%5D%2C%22colorspace%22%3A%22RGB%22%2C%22ratios%22%3A%5B%22-1.12%22%2C%221.45%22%2C%222.05%22%2C%223.02%22%2C%224.54%22%2C%227%22%2C%2210.86%22%5D%2C%22smooth%22%3Afalse%7D%5D%2C%22lightness%22%3A11%2C%22contrast%22%3A1%2C%22saturation%22%3A100%2C%22formula%22%3A%22wcag2%22%7D *! */
/* @media (prefers-color-scheme: dark) { */
/*     :root { */
/*         --background-color: #1d1d1d; */
/*         --gray-50: #101010; */
/*         --gray-100: #393939; */
/*         --gray-200: #4f4f4f; */
/*         --gray-300: #686868; */
/*         --gray-400: #848484; */
/*         --gray-500: #a7a7a7; */
/*         --gray-600: #cfcfcf; */
/*         --purple-100: #3c1e95; */
/*         --purple-200: #522acd; */
/*         --purple-300: #6f46ed; */
/*         --purple-400: #ffffff; */
/*         --purple-500: #b099f5; */
/*         --purple-600: #d5c8fa; */
/*         --red-100: #721200; */
/*         --red-200: #9c1900; */
/*         --red-300: #cc2000; */
/*         --red-400: #e95034; */
/*         --red-500: #f08c79; */
/*         --red-600: #f7c3ba; */
/*         --highlight-hover: rgb(255 255 255 / 0.1); */
/*         --highlight-pressed: rgb(255 255 255 / 0.2); */
/*     } */
/* } */

/* !* Semantic colors *! */
/* :root { */
/*     --focus-ring-color: var(--purple-400); */
/*     --text-color: var(--gray-600); */
/*     --text-color-base: var(--gray-500); */
/*     --text-color-hover: var(--gray-600); */
/*     --text-color-disabled: var(--gray-200); */
/*     --text-color-placeholder: var(--gray-400); */
/*     --link-color: var(--purple-500); */
/*     --link-color-secondary: var(--gray-500); */
/*     --link-color-pressed: var(--purple-600); */
/*     --border-color: var(--gray-300); */
/*     --border-color-hover: var(--gray-400); */
/*     --border-color-pressed: var(--gray-400); */
/*     --border-color-disabled: var(--gray-100); */
/*     --field-background: var(--gray-50); */
/*     --field-text-color: var(--gray-600); */
/*     --overlay-background: var(--gray-50); */
/*     --button-background: var(--gray-50); */
/*     --button-background-pressed: var(--background-color); */
/*     !* these colors are the same between light and dark themes */
/*      * to ensure contrast with the foreground color *! */
/*     --highlight-background: #ffffff; !* purple-300 from dark theme, 3.03:1 against background-color *! */
/*     --highlight-background-pressed: #ffffff; !* purple-200 from dark theme *! */
/*     --highlight-background-invalid: #cc2000; !* red-300 from dark theme *! */
/*     --highlight-foreground: white; !* 5.56:1 against highlight-background *! */
/*     --highlight-foreground-pressed: #ddd; */
/*     --highlight-overlay: rgb(from #6f46ed r g b / 15%); */
/*     --invalid-color: var(--red-400); */
/*     --invalid-color-pressed: var(--red-500); */
/* } */

/* !* Windows high contrast mode overrides *! */
/* @media (forced-colors: active) { */
/*     :root { */
/*         --background-color: Canvas; */
/*         --focus-ring-color: Highlight; */
/*         --text-color: ButtonText; */
/*         --text-color-base: ButtonText; */
/*         --text-color-hover: ButtonText; */
/*         --text-color-disabled: GrayText; */
/*         --text-color-placeholder: ButtonText; */
/*         --link-color: LinkText; */
/*         --link-color-secondary: LinkText; */
/*         --link-color-pressed: LinkText; */
/*         --border-color: ButtonBorder; */
/*         --border-color-hover: ButtonBorder; */
/*         --border-color-pressed: ButtonBorder; */
/*         --border-color-disabled: GrayText; */
/*         --field-background: Field; */
/*         --field-text-color: FieldText; */
/*         --overlay-background: Canvas; */
/*         --button-background: ButtonFace; */
/*         --button-background-pressed: ButtonFace; */
/*         --highlight-background: Highlight; */
/*         --highlight-background-pressed: Highlight; */
/*         --highlight-background-invalid: LinkText; */
/*         --highlight-foreground: HighlightText; */
/*         --highlight-foreground-pressed: HighlightText; */
/*         --invalid-color: LinkText; */
/*         --invalid-color-pressed: LinkText; */
/*     } */
/* } */
/* .react-aria-SearchField { */
/*     display: grid; */
/*     grid-template-areas: "label label" */
/*                        "input button" */
/*                        "help  help"; */
/*     grid-template-columns: 1fr auto; */
/*     align-items: center; */
/*     width: fit-content; */
/*     color: var(--text-color); */

/*     .react-aria-Input { */
/*         grid-area: input; */
/*         padding: 0.286rem 1.714rem 0.286rem 0.286rem; */
/*         margin: 0; */
/*         border: 1px solid var(--border-color); */
/*         border-radius: 6px; */
/*         background: var(--field-background); */
/*         font-size: 1.143rem; */
/*         color: var(--field-text-color); */

/*         &::-webkit-search-cancel-button, */
/*         &::-webkit-search-decoration { */
/*             -webkit-appearance: none; */
/*         } */

/*         &[data-focused] { */
/*             outline: 2px solid var(--focus-ring-color); */
/*             outline-offset: -1px; */
/*         } */
/*     } */

/*     .react-aria-Button { */
/*         grid-area: button; */
/*         width: 1.143rem; */
/*         height: 1.143rem; */
/*         border-radius: 1.143rem; */
/*         margin-left: -1.429rem; */
/*         font-size: 0.857rem; */
/*         line-height: 0.857rem; */
/*         vertical-align: middle; */
/*         text-align: center; */
/*         background: var(--gray-500); */
/*         color: var(--gray-50); */
/*         border: none; */
/*         padding: 0; */

/*         &[data-pressed] { */
/*             background: var(--gray-600); */
/*         } */
/*     } */

/*     &[data-empty] button { */
/*         display: none; */
/*     } */
/* } */


/* .react-aria-Popover { */
/*     --background-color: var(--overlay-background); */

/*     border: 1px solid var(--border-color); */
/*     box-shadow: 0 8px 20px rgba(0 0 0 / 0.1); */
/*     border-radius: 6px; */
/*     background: var(--background-color); */
/*     color: var(--text-color); */
/*     outline: none; */
/*     max-width: 250px; */
/*     box-sizing: border-box; */

/*     .react-aria-OverlayArrow svg { */
/*         display: block; */
/*         fill: var(--background-color); */
/*         stroke: var(--border-color); */
/*         stroke-width: 1px; */
/*     } */

/*     &[data-placement=top] { */
/*         --origin: translateY(8px); */

/*         &:has(.react-aria-OverlayArrow) { */
/*             margin-bottom: 6px; */
/*         } */
/*     } */

/*     &[data-placement=bottom] { */
/*         --origin: translateY(-8px); */

/*         &:has(.react-aria-OverlayArrow) { */
/*             margin-top: 6px; */
/*         } */

/*         .react-aria-OverlayArrow svg { */
/*             transform: rotate(180deg); */
/*         } */
/*     } */

/*     &[data-placement=right] { */
/*         --origin: translateX(-8px); */

/*         &:has(.react-aria-OverlayArrow) { */
/*             margin-left: 6px; */
/*         } */

/*         .react-aria-OverlayArrow svg { */
/*             transform: rotate(90deg); */
/*         } */
/*     } */

/*     &[data-placement=left] { */
/*         --origin: translateX(8px); */

/*         &:has(.react-aria-OverlayArrow) { */
/*             margin-right: 6px; */
/*         } */

/*         .react-aria-OverlayArrow svg { */
/*             transform: rotate(-90deg); */
/*         } */
/*     } */

/*     &[data-entering] { */
/*         animation: popover-slide 200ms; */
/*     } */

/*     &[data-exiting] { */
/*         animation: popover-slide 200ms reverse ease-in; */
/*     } */
/* } */

/* @keyframes popover-slide { */
/*     from { */
/*         transform: var(--origin); */
/*         opacity: 0; */
/*     } */

/*     to { */
/*         transform: translateY(0); */
/*         opacity: 1; */
/*     } */
/* } */

/* .react-aria-Button { */
/*     color: var(--text-color); */
/*     background: var(--button-background); */
/*     border: 1px solid var(--border-color); */
/*     border-radius: 4px; */
/*     appearance: none; */
/*     vertical-align: middle; */
/*     font-size: 1rem; */
/*     text-align: center; */
/*     margin: 0; */
/*     outline: none; */
/*     padding: 6px 10px; */
/*     text-decoration: none; */

/*     &[data-pressed] { */
/*         box-shadow: inset 0 1px 2px rgb(0 0 0 / 0.1); */
/*         background: var(--button-background-pressed); */
/*         border-color: var(--border-color-pressed); */
/*     } */

/*     &[data-focus-visible] { */
/*         outline: 2px solid var(--focus-ring-color); */
/*         outline-offset: -1px; */
/*     } */
/* } */


/* .react-aria-Switch { */
/*     display: flex; */
/*     align-items: center; */
/*     gap: 0.571rem; */
/*     font-size: 1.143rem; */
/*     color: var(--text-color); */
/*     forced-color-adjust: none; */

/*     .indicator { */
/*         width: 2rem; */
/*         height: 1.143rem; */
/*         border: 2px solid var(--border-color); */
/*         background: var(--background-color); */
/*         border-radius: 1.143rem; */
/*         transition: all 200ms; */
/*         cursor: pointer; */
/*         &:before { */
/*             content: ''; */
/*             display: block; */
/*             margin: 0.090rem; */
/*             width: 0.857rem; */
/*             height: 0.857rem; */
/*             background: var(--highlight-background); */
/*             border-radius: 16px; */
/*             transition: all 200ms; */
/*         } */
/*     } */

/*     &[data-pressed] .indicator { */
/*         border-color: var(--border-color-pressed); */

/*         &:before { */
/*             background: var(--highlight-background-pressed); */
/*         } */
/*     } */

/*     &[data-selected] { */
/*         .indicator { */
/*             border-color: var(--highlight-background); */
/*             background: var(--highlight-background); */

/*             &:before { */
/*                 background: var(--field-background); */
/*                 transform: translateX(100%); */
/*             } */
/*         } */

/*         &[data-pressed] { */
/*             .indicator { */
/*                 border-color: var(--highlight-background-pressed); */
/*                 background: var(--highlight-background-pressed); */
/*             } */
/*         } */
/*     } */

/*     &[data-focus-visible] .indicator { */
/*         outline: 1px solid var(--focus-ring-color); */
/*         outline-offset: 0.5px; */
/*         color: #212121; */
/*     } */
/* } */

/* .react-aria-ListBox { */
/*     display: flex; */
/*     flex-direction: column; */
/*     max-height: inherit; */
/*     overflow: auto; */
/*     padding: 2px; */
/*     border: 1px solid var(--border-color); */
/*     border-radius: 6px; */
/*     background: var(--overlay-background); */
/*     forced-color-adjust: none; */
/*     outline: none; */
/*     width: 250px; */
/*     max-height: 300px; */
/*     min-height: 100px; */
/*     box-sizing: border-box; */

/*     &[data-focus-visible] { */
/*         outline: 2px solid var(--focus-ring-color); */
/*         outline-offset: -1px; */
/*     } */
/* } */

/* .react-aria-ListBoxItem { */
/*     margin: 2px; */
/*     padding: 0.286rem 0.571rem; */
/*     border-radius: 6px; */
/*     outline: none; */
/*     cursor: default; */
/*     color: var(--text-color); */
/*     font-size: 1.072rem; */
/*     position: relative; */
/*     display: flex; */
/*     flex-direction: column; */

/*     &[data-focus-visible] { */
/*         outline: 2px solid var(--focus-ring-color); */
/*         outline-offset: -2px; */
/*     } */

/*     &[data-selected] { */
/*         background: var(--highlight-background); */
/*         color: var(--highlight-foreground); */

/*         &[data-focus-visible] { */
/*             outline-color: var(--highlight-foreground); */
/*             outline-offset: -4px; */
/*         } */
/*     } */
/* } */

/* !* .react-aria-Table { *! */
/* !*     padding: 0.286rem; *! */
/* !*     background-clip: padding-box;  *! */
/* !*     border-radius: 12px; *! */
/* !*     background: #fff; *! */
/* !*     outline: none; *! */
/* !*     border-spacing: 0; *! */
/* !*     min-height: 100px; *! */
/* !*     align-self: start; *! */
/* !*     margin-top: 20px; *! */
/* !*     width: 100%; *! */
/* !*     word-break: break-word; *! */
/* !*     forced-color-adjust: none; *! */

/* !*     &[data-focus-visible] { *! */
/* !*         outline: 2px solid var(--focus-ring-color); *! */
/* !*         outline-offset: -1px; *! */
/* !*     } *! */

/* !*     .react-aria-TableHeader { *! */
/* !*         color: var(--text-color); *! */
/* !*         background-color: #252f3a; *! */
/* !*         border-radius: 10px; *! */
/* !*         &:after { *! */
/* !*             content: ''; *! */
/* !*             display: table-row; *! */
/* !*             height: 2px; *! */
/* !*             border-radius: 20px; *! */
/* !*         } *! */

/* !*         & tr:last-child .react-aria-Column { *! */
/* !*             !* border-bottom: 1px solid var(--border-color); *! *! */
/* !*             cursor: default; *! */
/* !*         } *! */
/* !*     } *! */

/* !*     .react-aria-Row { *! */
/* !*         --radius-top: 6px; *! */
/* !*         --radius-bottom: 6px; *! */
/* !*         --radius: var(--radius-top) var(--radius-top) var(--radius-bottom) var(--radius-bottom); *! */
/* !*         border-radius: var(--radius); *! */
/* !*         clip-path: inset(0 round var(--radius)); !* firefox *! *! */
/* !*         outline: none; *! */
/* !*         cursor: default; *! */
/* !*         color: var(--text-color); *! */
/* !*         font-size: 1.072rem; *! */
/* !*         position: relative; *! */
/* !*         transform: scale(1); *! */
/* !*         height: 100%; *! */


/* !*         &[data-focus-visible] { *! */
/* !*             outline: 2px solid var(--focus-ring-color); *! */
/* !*             outline-offset: -2px; *! */
/* !*         } *! */

/* !*         &[data-pressed] { *! */
/* !*             background: var(--gray-100); *! */
/* !*         } *! */

/* !*         &[data-selected] { *! */
/* !*             background: var(--highlight-background); *! */
/* !*             color: var(--highlight-foreground); *! */
/* !*             --focus-ring-color: var(--highlight-foreground); *! */

/* !*             &[data-focus-visible], *! */
/* !*             .react-aria-Cell[data-focus-visible] { *! */
/* !*                 outline-offset: -4px; *! */
/* !*             } *! */
/* !*         } *! */

/* !*         &[data-disabled] { *! */
/* !*             color: var(--text-color-disabled); *! */
/* !*         } *! */
/* !*     } *! */

/* !*     .react-aria-Cell, *! */
/* !*     .react-aria-Column { *! */
/* !*         padding: 4px 8px; *! */
/* !*         text-align: left; *! */
/* !*         outline: none; *! */

/* !*         &[data-focus-visible] { *! */
/* !*             outline: 2px solid var(--focus-ring-color); *! */
/* !*             outline-offset: -2px; *! */
/* !*         } *! */
/* !*     } *! */

/* !*     .react-aria-Cell { *! */
/* !*         transform: translateZ(0); *! */

/* !*         &:first-child { *! */
/* !*             border-radius: var(--radius-top) 0 0 var(--radius-bottom); *! */
/* !*         } *! */

/* !*         &:last-child { *! */
/* !*             border-radius: 0 var(--radius-top) var(--radius-bottom) 0; *! */
/* !*         } *! */
/* !*     } *! */

/* !*     !* join selected items if :has selector is supported *! *! */
/* !*     @supports selector(:has(.foo)) { *! */
/* !*         .react-aria-Row[data-selected]:has(+ [data-selected]), *! */
/* !*         .react-aria-Row[data-selected]:has(+ .react-aria-DropIndicator + [data-selected]) { *! */
/* !*             --radius-bottom: 0px; *! */
/* !*         } *! */

/* !*         .react-aria-Row[data-selected] + [data-selected], *! */
/* !*         .react-aria-Row[data-selected] + .react-aria-DropIndicator + [data-selected]{ *! */
/* !*             --radius-top: 0px; *! */
/* !*         } *! */
/* !*     } *! */
/* !* } *! */

/* !* :where(.react-aria-Row) .react-aria-Checkbox { *! */
/* !*     --selected-color: var(--highlight-foreground); *! */
/* !*     --selected-color-pressed: var(--highlight-foreground-pressed); *! */
/* !*     --checkmark-color: var(--highlight-background); *! */
/* !*     --background-color: var(--highlight-background); *! */
/* !* } *! */

/* .react-aria-ProgressBar { */
/*     display: grid; */
/*     grid-template-areas: "label value" */
/*                        "bar bar"; */
/*     grid-template-columns: 1fr auto; */
/*     gap: 4px; */
/*     width: 250px; */
/*     color: var(--text-color); */

/*     .value { */
/*         grid-area: value; */
/*     } */

/*     .bar { */
/*         grid-area: bar; */
/*         box-shadow: inset 0px 0px 0px 1px var(--border-color); */
/*         forced-color-adjust: none; */
/*         height: 10px; */
/*         border-radius: 5px; */
/*         overflow: hidden; */
/*         will-change: transform; */
/*     } */

/*     .fill { */
/*         background: var(--highlight-background); */
/*         height: 100%; */
/*     } */
/* } */

/* .react-aria-ModalOverlay { */
/*     position: fixed; */
/*     top: 0; */
/*     left: 0; */
/*     width: 100vw; */
/*     height: var(--visual-viewport-height); */
/*     background: rgba(0 0 0 / .5); */
/*     display: flex; */
/*     align-items: center; */
/*     justify-content: center; */
/*     z-index: 100; */

/*     &[data-entering] { */
/*         animation: modal-fade 200ms; */
/*     } */

/*     &[data-exiting] { */
/*         animation: modal-fade 150ms reverse ease-in; */
/*     } */
/* } */

/* .react-aria-Modal { */
/*     box-shadow: 0 8px 20px rgba(0 0 0 / 0.1); */
/*     border-radius: 6px; */
/*     background: var(--overlay-background); */
/*     color: var(--text-color); */
/*     border: 1px solid var(--gray-400); */
/*     outline: none; */
/*     max-width: 300px; */

/*     &[data-entering] { */
/*         animation: modal-zoom 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275); */
/*     } */

/*     .react-aria-TextField { */
/*         margin-bottom: 8px; */
/*     } */
/* } */

/* @keyframes modal-fade { */
/*     from { */
/*         opacity: 0; */
/*     } */

/*     to { */
/*         opacity: 1; */
/*     } */
/* } */

/* @keyframes modal-zoom { */
/*     from { */
/*         transform: scale(0.8); */
/*     } */

/*     to { */
/*         transform: scale(1); */
/*     } */
/* } */


/* .react-aria-TextField { */
/*     display: flex; */
/*     flex-direction: column; */
/*     width: fit-content; */
/*     color: var(--text-color); */

/*     .react-aria-Input, */
/*     .react-aria-TextArea { */
/*         padding: 0.286rem; */
/*         margin: 0; */
/*         border: 1px solid var(--border-color); */
/*         border-radius: 6px; */
/*         background: var(--field-background); */
/*         font-size: 1.143rem; */
/*         color: var(--field-text-color); */

/*         &[data-focused] { */
/*             outline: 2px solid var(--focus-ring-color); */
/*             outline-offset: -1px; */
/*         } */
/*     } */
/* } */

/* .react-aria-Checkbox { */
/*     --selected-color: var(--highlight-background); */
/*     --selected-color-pressed: var(--highlight-background-pressed); */
/*     --checkmark-color: #000; */

/*     display: flex; */
/*     align-items: center; */
/*     gap: 0.571rem; */
/*     font-size: 1.143rem; */
/*     color: var(--text-color); */
/*     forced-color-adjust: none; */

/*     .checkbox { */
/*         width: 1.143rem; */
/*         height: 1.143rem; */
/*         border: 2px solid var(--border-color); */
/*         border-radius: 4px; */
/*         transition: all 200ms; */
/*         display: flex; */
/*         align-items: center; */
/*         justify-content: center; */
/*         color: #b50808; */
/*     } */

/*     svg { */
/*         width: 1rem; */
/*         height: 1rem; */
/*         fill: none; */
/*         stroke: var(--checkmark-color); */
/*         stroke-width: 3px; */
/*         stroke-dasharray: 22px; */
/*         stroke-dashoffset: 66; */
/*         transition: all 200ms; */
/*     } */

/*     &[data-pressed] .checkbox { */
/*         border-color: var(--border-color-pressed); */
/*     } */

/*     &[data-focus-visible] .checkbox { */
/*         outline: 2px solid var(--focus-ring-color); */
/*         outline-offset: 2px; */
/*     } */

/*     &[data-selected], */
/*     &[data-indeterminate] { */
/*         .checkbox { */
/*             border-color: var(--selected-color); */
/*             background: var(--selected-color); */
/*         } */

/*         &[data-pressed] .checkbox { */
/*             border-color: var(--selected-color-pressed); */
/*             background: var(--selected-color-pressed); */
/*         } */

/*         svg { */
/*             stroke-dashoffset: 44; */
/*         } */
/*     } */

/*     &[data-indeterminate] { */
/*         & svg { */
/*             stroke: none; */
/*             fill: var(--checkmark-color); */
/*         } */
/*     } */
/* } */

/* !* .react-aria-TableHeader { *! */
/* !*     width: 100%; *! */
/* !*     text-align: center; *! */
/* !*     display: flex; *! */
/* !*     align-items: center; *! */
/* !*     justify-content: center; *! */
/* !*     &:after { *! */
/* !*         content: ''; *! */
/* !*         display: table-row; *! */
/* !*         height: 2px; *! */
/* !*     } *! */

/* !*     & tr { *! */
/* !*     } *! */

/* !* } *! */

